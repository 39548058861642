export const characters = {
  abk: [
    "Adler",
    "Akatsuki",
    "Anonym",
    "Blitztank",
    "Elektrosoldat",
    "Fritz",
    "Kanae",
    "Marilyn Sue",
    "Murakumo",
    "Mycale",
    "Perfecti",
    "Sai",
    "Wei",
  ],
  sf3: [
    "Akuma",
    "Alex",
    "Chun-Li",
    "Dudley",
    "Elena",
    "Hugo",
    "Ibuki",
    "Ken",
    "Makoto",
    "Necro",
    "Oro",
    "Q",
    "Remy",
    "Ryu",
    "Sean",
    "Twelve",
    "Urien",
    "Yang",
    "Yun",
  ],
  sfv: [
    "Abigail",
    "Akira",
    "Akuma",
    "Alex",
    "Balrog",
    "Birdie",
    "Blanka",
    "Cammy",
    "Chun-Li",
    "Cody",
    "Dan",
    "Dhalsim",
    "Ed",
    "E. Honda",
    "Falke",
    "F.A.N.G",
    "G",
    "Gill",
    "Guile",
    "Ibuki",
    "Juri",
    "Kage",
    "Karin",
    "Ken",
    "Kolin",
    "Laura",
    "Lucia",
    "Luke",
    "M.Bison",
    "Menat",
    "Nash",
    "Necalli",
    "Oro",
    "Poison",
    "Rashid",
    "Rose",
    "R.Mika",
    "Ryu",
    "Sagat",
    "Sakura",
    "Seth",
    "Urien",
    "Vega",
    "Zangief",
    "Zeku",
  ],
  p4au: [
    "Aigis",
    "Aigis (Shadow)",
    "Akihiko Sanada",
    "Akihiko Sanada (Shadow)",
    "Chie Satonaka",
    "Chie Satonaka (Shadow)",
    "Elizabeth",
    "Junpei Iori",
    "Junpei Iori (Shadow)",
    "Kanji Tatsumi",
    "Kanji Tatsumi (Shadow)",
    "Ken Amada",
    "Ken Amada (Shadow)",
    "Labrys",
    "Labrys (Shadow)",
    "Margaret",
    "Marie",
    "Minazuki",
    "Mitsuru Kirijo",
    "Mitsuru Kirijo (Shadow)",
    "Naoto Shirogane",
    "Naoto Shirogane (Shadow)",
    "Rise Kujikawa",
    "Rise Kujikawa (Shadow)",
    "Shadow Labrys",
    "Sho",
    "Teddie",
    "Teddie (Shadow)",
    "Tohru Adachi",
    "Yosuke Hanamura",
    "Yosuke Hanamura (Shadow)",
    "Yu Narukami",
    "Yu Narukami (Shadow)",
    "Yukari Takeba",
    "Yukari Takeba (Shadow)",
    "Yukiko Amagi",
    "Yukiko Amagi (Shadow)",
  ],
  kofxv: [
    "Andy Bogard",
    "Ángel",
    "Antonov",
    "Ash Crimson",
    "Athena Asamiya",
    "B. Jenet",
    "Benimaru Nikaido",
    "Billy Kane",
    "Blue Mary",
    "Chizuru Kagura",
    "Chris",
    "Clark Still",
    "Darli Dagger",
    "Dolores",
    "Duo Lon",
    "Elisabeth Blanctorche",
    "Gato",
    "Geese Howard",
    "Goenitz",
    "Haohmaru",
    "Heidern",
    "Hinako",
    "Iori Yagami",
    "Isla",
    "Joe Higashi",
    "K'",
    "Kim Kaphwan",
    "King",
    "King of Dinosaurs",
    "Krohnen",
    "Kukri",
    "Kula Diamond",
    "Kyo Kusanagi",
    "Leona Heidern",
    "Luong",
    "Mai Shiranui",
    "Maxima",
    "Meitenkun",
    "Najd",
    "Nakoruru",
    "Omega Rugal",
    "Orochi Chris",
    "Orochi Shermie",
    "Orochi Yashiro",
    "Ralf Jones",
    "Ramón",
    "Robert Garcia",
    "Rock Howard",
    "Ryo Sakazaki",
    "Ryuji Yamazaki",
    "Shermie",
    "Shun'ei",
    "Shingo Yabuki",
    "Sylvie",
    "Terry Bogard",
    "Vanessa",
    "Whip",
    "Yashiro Nanakase",
    "Yuri Sakazaki",
  ],
  umvc3: [
    "Akuma",
    "Amaterasu",
    "Arthur",
    "Captain America",
    "C. Viper",
    "Chris",
    "Chun-Li",
    "Dante",
    "Deadpool",
    "Doctor Doom",
    "Doctor Strange",
    "Dormammu",
    "Felicia",
    "Firebrand",
    "Frank West",
    "Ghost Rider",
    "Haggar",
    "Hawkeye",
    "Hsien Ko",
    "Hulk",
    "Iron Fist",
    "Iron Man",
    "Jill",
    "Magneto",
    "Morrigan",
    "M.O.D.O.K.",
    "Nemesis",
    "Nova",
    "Phoenix",
    "Phoenix Wright",
    "Rocket Raccoon",
    "Ryu",
    "Sentinel",
    "She Hulk",
    "Shuma-Gorath",
    "Spencer",
    "Spider-Man",
    "Storm",
    "Strider",
    "Super-Skrull",
    "Taskmaster",
    "Thor",
    "Trish",
    "Tron Bonne",
    "Vergil",
    "Viewtiful Joe",
    "Wesker",
    "Wolverine",
    "X-23",
    "Zero",
  ],
  dnf: [
    "Battle Mage",
    "Berserker",
    "Brawler",
    "Crusader",
    "Dragon Knight",
    "Enchantress",
    "Ghostblade",
    "Grappler",
    "Hitman",
    "Inquisitor",
    "Kunoichi",
    "Launcher",
    "Lost Warrior",
    "Monk",
    "Nen Master",
    "Ranger",
    "Spectre",
    "Striker",
    "Swift Master",
    "Troubleshooter",
    "Vanguard",
  ],
  samsho: [
    "Amakusa",
    "Baiken",
    "Basara",
    "Cham Cham",
    "Charlotte",
    "Darli",
    "Earthquake",
    "Galford",
    "Genjuro",
    "Gongsun Li",
    "Hanzo",
    "Haohmaru",
    "Hibiki",
    "Iroha",
    "Jubei",
    "Kazuki",
    "Kyoshiro",
    "Mina",
    "Nakoruru",
    "Rimururu",
    "Shiki",
    "Shizumaru",
    "Sogetsu",
    "Tam Tam",
    "Ukyo",
    "Wan-Fu",
    "Warden",
    "Wu",
    "Yashamaru",
    "Yoshitora",
  ],
  sf6: [
    "A.K.I.",
    "Akuma",
    "Blanka",
    "Cammy",
    "Chun-Li",
    "Dee Jay",
    "Dhalsim",
    "E. Honda",
    "Ed",
    "Guile",
    "Jamie",
    "JP",
    "Juri",
    "Ken",
    "Kimberly",
    "Lily",
    "Luke",
    "M. Bison",
    "Manon",
    "Marisa",
    "Rashid",
    "Ryu",
    "Zangief",
  ],
  unib: [
    "Akatsuki",
    "Byakuya",
    "Carmine",
    "Chaos",
    "Eltnum",
    "Enkidu",
    "Gordeau",
    "Hilda",
    "Hyde",
    "Kaguya",
    "Kuon",
    "Linne",
    "Londrekia",
    "Merkava",
    "Mika",
    "Nanase",
    "Orie",
    "Phonon",
    "Seth",
    "Tsurugi",
    "Uzuki",
    "Vatista",
    "Wagner",
    "Waldstein",
    "Yuzuriha",
  ],
  xrd: [
    "Answer",
    "Axl Low",
    "Baiken",
    "Bedman",
    "Chipp Zanuff",
    "Dizzy",
    "Elphelt Valentine",
    "Faust",
    "I-No",
    "Jack-O'",
    "Jam Kuradoberi",
    "Johnny",
    "Kum Haehyun",
    "Ky Kiske",
    "Leo Whitefang",
    "May",
    "Millia Rage",
    "Potemkin",
    "Ramlethal Valentine",
    "Raven",
    "Sin Kiske",
    "Slayer",
    "Sol Badguy",
    "Venom",
    "Zato-1",
  ],
  acpr: [
    "A.B.A.",
    "Anji Mito",
    "Axl Low",
    "Baiken",
    "Bridget",
    "Chipp Zanuff",
    "Dizzy",
    "Eddie",
    "Faust",
    "I-No",
    "Jam Kuradoberi",
    "Johnny",
    "Justice",
    "Kliff Undersn",
    "Ky Kiske",
    "May",
    "Millia Rage",
    "Order-Sol",
    "Potemkin",
    "Robo-Ky",
    "Slayer",
    "Sol Badguy",
    "Testament",
    "Venom",
    "Zappa",
  ],
  gbvs: [
    "2B",
    "Anila",
    "Anre",
    "Avatar Belial",
    "Beatrix",
    "Beelzebub",
    "Belial",
    "Cagliostro",
    "Charlotta",
    "Djeeta",
    "Eustace",
    "Ferry",
    "Gran",
    "Grimnir",
    "Katalina",
    "Ladiva",
    "Lancelot",
    "Lowain",
    "Lucilius",
    "Metera",
    "Narmaya",
    "Nier",
    "Percival",
    "Seox",
    "Siegfried",
    "Soriz",
    "Vane",
    "Vaseraga",
    "Versusia",
    "Vira",
    "Yuel",
    "Zeta",
    "Zooey",
  ],
  bbcf: [
    "Amane Nishiki",
    "Arakune",
    "Azrael",
    "Bang Shishigami",
    "Bullet",
    "Carl Clover",
    "Celica A. Mercury",
    "Es",
    "Hakumen",
    "Hazama",
    "Hibiki Kohaku",
    "Iron Tager",
    "Izanami",
    "Izayoi",
    "Jin Kisaragi",
    "Jubei",
    "Kagura Mutsuki",
    "Kokonoe",
    "Lambda-11",
    "Litchi Faye Ling",
    "Mai Natsume",
    "Makoto Nanaya",
    "Mu-12",
    "Naoto Kurogane",
    "Nine the Phantom",
    "Noel Vermillion",
    "Nu-13",
    "Platinum the Trinity",
    "Rachel Alucard",
    "Ragna the Bloodedge",
    "Relius Clover",
    "Susanoo",
    "Taokaka",
    "Tsubaki Yayoi",
    "Valkenhayn R. Hellsing",
    "Yuuki Terumi",
  ],
  bbtag: [
    "Adachi",
    "Aegis",
    "Akatsuki",
    "Akihiko",
    "Azrael",
    "Blake",
    "Blitztank",
    "Carmine",
    "Celica",
    "Chie",
    "Elizabeth",
    "Es",
    "Gordeau",
    "Hakumen",
    "Hazama",
    "Heart",
    "Hilda",
    "Hyde",
    "Izayoi",
    "Jin",
    "Jubei",
    "Kanji",
    "Labrys",
    "Linne",
    "Mai",
    "Makoto",
    "Merkava",
    "Mika",
    "Mitsuru",
    "Naoto",
    "Naoto Kurogane",
    "Neo",
    "Nine",
    "Noel",
    "Nu",
    "Orie",
    "Platinum",
    "Rachel",
    "Ragna",
    "Ruby",
    "Seth",
    "Susanoo",
    "Tager",
    "Teddie",
    "Vatista",
    "Waldstein",
    "Weiss",
    "Yang",
    "Yosuke",
    "Yu",
    "Yukiko",
    "Yumi",
    "Yuzuriha",
  ],
  strive: [
    "A.B.A",
    "Anji Mito",
    "Asuka R♯",
    "Axl Low",
    "Baiken",
    "Bedman?",
    "Bridget",
    "Chipp Zanuff",
    "Elphelt Valentine",
    "Faust",
    "Giovanna",
    "Goldlewis Dickinson",
    "Happy Chaos",
    "I-No",
    "Jack-O'",
    "Johnny",
    "Ky Kiske",
    "Leo Whitefang",
    "May",
    "Millia Rage",
    "Nagoriyuki",
    "Potemkin",
    "Ramlethal Valentine",
    "Sin Kiske",
    "Slayer",
    "Sol Badguy",
    "Testament",
    "Zato-1",
  ],
  tfh: [
    "Arizona",
    "Baihe",
    "Nidra",
    "Oleander",
    "Paprika",
    "Pom",
    "Shanty",
    "Stronghoof",
    "Texas",
    "Tianhuo",
    "Velvet",
  ],
  scon4: [
    "Anko",
    "Choji",
    "Gaara",
    "Haku",
    "Hinata",
    "Awakened Hinata",
    "Ino",
    "Iruka",
    "Itachi",
    "Jiraiya",
    "Jirobo",
    "Kabuto",
    "Kakashi",
    "Kankuro",
    "Kiba",
    "Kidomaru",
    "Kimimaro",
    "Kisame",
    "Might Guy",
    "Mizuki",
    "Naruto",
    "OTK Naruto",
    "Neji",
    "Orochimaru",
    "Rock Lee",
    "Sakura",
    "Sasuke",
    "CS2 Sasuke",
    "Shikamaru",
    "Sakon & Ukon",
    "Shino",
    "Tayuya",
    "Temari",
    "Tenten",
    "Third Hokage",
    "Tsunade",
    "Zabuza",
  ],
  vsav: [
    "Anakaris",
    "Aulbath",
    "Bishamon",
    "Bulleta",
    "Demitri",
    "Felicia",
    "Gallon",
    "Jedah",
    "Lei-Lei",
    "Lilith",
    "Morrigan",
    "Q-Bee",
    "Sasquatch",
    "Victor",
    "Zabel",
  ],
  f8uc: [
    "Archer",
    "Assassin",
    "Bazett",
    "Berserker",
    "Caster",
    "Gilgamesh",
    "Kirei",
    "Lancer",
    "Leysritt",
    "Luviagelita",
    "Rider",
    "Rin",
    "Saber",
    "Saber Alter",
    "Sakura",
    "Shirou",
    "Zero Lancer",
  ],
  d012: [
    "Bartz Klauser",
    "Cecil Harvey",
    "Cloud of Darkness",
    "Cloud Strife",
    "Exdeath",
    "Feral Chaos",
    "Firion",
    "Gabranth",
    "Garland",
    "Gilgamesh",
    "Golbez",
    "Jecht",
    "Kain Highwind",
    "Kefka Palazzo",
    "Kuja",
    "Laguna Loire",
    "Lightning",
    "Onion Knight",
    "Prishe",
    "Sephiroth",
    "Shantotto",
    "Squall Leonhart",
    "Terra Branford",
    "The Emperor",
    "Tidus",
    "Tifa Lockhart",
    "Ultimecia",
    "Vaan",
    "Warrior of Light",
    "Yuna",
    "Zidane Tribal",
  ],
  tkn8: [
    "Alisa",
    "Asuka",
    "Azucena",
    "Bryan",
    "Claudio",
    "Devil Jin",
    "Dragunov",
    "Eddy",
    "Feng",
    "Hwoarang",
    "Jack-8",
    "Jin",
    "Jun",
    "Kazuya",
    "King",
    "Kuma",
    "Lars",
    "Law",
    "Lee",
    "Leo",
    "Leroy",
    "Lidia",
    "Lili",
    "Nina",
    "Panda",
    "Paul",
    "Raven",
    "Reina",
    "Shaheen",
    "Steve",
    "Victor",
    "Xiaoyu",
    "Yoshimitsu",
    "Zafina",
  ],
  asbr: [
    "Abbacchio",
    "Akira",
    "Anasui",
    "Avdol",
    "Baoh",
    "Bucciarati",
    "Caesar",
    "Diavolo",
    "Diego",
    "AW Diego",
    "Dio Brando",
    "DIO",
    "Ermes",
    "Esidisi",
    "Foo Fighters",
    "Fugo",
    "Ghiaccio",
    "Giorno",
    "Gyro",
    "Hol Horse",
    "Iggy",
    "Johnny",
    "Jolyne",
    "Jonathan",
    "Joseph",
    "Josuke (Part 4)",
    "Josuke (Part 8)",
    "Jotaro (Part 3)",
    "Jotaro (Part 4)",
    "Kakyoin",
    "Kars",
    "Keicho",
    "Kira",
    "Koichi",
    "Kosaku",
    "Lisa Lisa",
    "Mariah",
    "Mista",
    "Narancia",
    "Okuyasu",
    "Old Joseph",
    "Pet Shop",
    "Polnareff",
    "Prosciutto",
    "Pucci (WS)",
    "Pucci (Final)",
    "Risotto",
    "Rohan",
    "Shigechi",
    "Speedwagon",
    "Stroheim",
    "Trish",
    "Valentine",
    "Vanilla Ice",
    "Wamuu",
    "Weather Report",
    "Wonder of U",
    "Yukako",
    "Yuya",
    "Zeppeli",
  ],
};
